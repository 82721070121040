import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { updateCalendarErrors } from './calendarErrorsHandler';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';
import { FlowElements } from '../../Hooks/useFlow';
import { isWaitingListFlow } from '../../../../utils/waitingList/waitingList';
import { AddError } from '../addError/addError';
import { getSelectedSlots } from '../../../../utils/selectedSlots/selectedSlots';
import { CalendarErrors } from '../../../../types/types';
import { BOOKINGS_CALENDAR_REFERRAL_INFO } from '../../../../constants/constants';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import {
  bookingsPaymentMethodSelectionNextClicked,
  bookingsContactInfoSaveSuccess,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { mapFlowTypeToBiProperties } from '../../../../utils/bi/mappers';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { getDynamicPricingInfo } from '../../../../utils/dynamicPricing/dynamicPricing';

export const submitErrors: CalendarErrors[] = [
  CalendarErrors.NO_SELECTED_LOCATION_ERROR,
  CalendarErrors.NO_SELECTED_DURATION_ERROR,
  CalendarErrors.NO_SELECTED_STAFF_MEMBER_ERROR,
  CalendarErrors.NO_SELECTED_CUSTOM_PREFERENCE_ERROR,
  CalendarErrors.NO_TIME_SELECTED_ERROR,
];

export type OnSubmit = () => void;

export function createOnSubmitAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
  addError: AddError,
  setFocusedElement: SetFocusedElement,
): OnSubmit {
  return async () => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { t, businessInfo, biLogger, wixSdkAdapter, activeFeatures } =
      context;
    const {
      selectedTime,
      selectableSlotsAtSelectedTime,
      selectedBookingPreferences,
      availableServices,
    } = state;
    let { calendarErrors } = state;

    const isTemplateMode = wixSdkAdapter.isTemplateMode();
    const isBookingEnabled = isBookingsEnabled({
      serviceType: availableServices[0]!.info.type,
      activeFeatures: activeFeatures!,
      isTemplateMode,
    });

    if (!selectedTime) {
      const calendarError = CalendarErrors.NO_TIME_SELECTED_ERROR;

      void biLogger.report(
        bookingsPaymentMethodSelectionNextClicked({
          userMessage: calendarError,
        }),
      );
      addError(calendarError);
      setFocusedElement(FlowElements.BOTTOM_SECTION);
    }

    if (selectableSlotsAtSelectedTime) {
      const bookingPreferences = getBookingPreferencesForSelectedTime({
        selectableSlotsAtSelectedTime,
        calendarErrors,
        selectedBookingPreferences,
        context,
        state,
      });

      updateCalendarErrors(
        bookingPreferences,
        addError,
        selectedBookingPreferences,
      );

      [{ calendarErrors }] = getControllerState();

      const isCalendarErrorsHasSubmitErrors = calendarErrors.some((error) =>
        submitErrors.includes(error),
      );

      const dateRegionalSettingsLocale =
        businessInfo!.dateRegionalSettingsLocale!;
      const selectedSlots = getSelectedSlots({
        selectableSlotsAtSelectedTime,
        dateRegionalSettingsLocale,
        t,
        selectedBookingPreferences,
      });
      const isWaitingList = isWaitingListFlow({
        selectableSlots: selectableSlotsAtSelectedTime,
        selectedBookingPreferences,
        bookingPreferences,
      });

      if (!isCalendarErrorsHasSubmitErrors && selectedSlots.length > 0) {
        const isBookingFormInstalled =
          await wixSdkAdapter.isBookingFormInstalled();
        if (wixSdkAdapter.isPreviewMode()) {
          if (!isBookingEnabled) {
            const service = availableServices[0].info.type;
            await wixSdkAdapter.openPreviewPremiumModal(
              service,
              BOOKINGS_CALENDAR_REFERRAL_INFO,
            );
          }
          return goToNextPage(
            actionFactoryParams,
            selectedSlots,
            isWaitingList,
            isBookingFormInstalled,
          );
        }
        if (wixSdkAdapter.isSiteMode()) {
          if (isBookingEnabled) {
            return goToNextPage(
              actionFactoryParams,
              selectedSlots,
              isWaitingList,
              isBookingFormInstalled,
            );
          } else {
            setState({
              dialog: {
                type: DialogType.PremiumViewer,
                state: DialogState.IDLE,
              },
            });
          }
        }
      } else {
        void biLogger.report(
          bookingsPaymentMethodSelectionNextClicked({
            selectedSlot: selectedTime,
            ...mapFlowTypeToBiProperties({ isWaitingList }),
          }),
        );
      }
    }
  };
}

export async function goToNextPage(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
  selectedSlots: SlotAvailability[],
  isWaitingList: boolean,
  isBookingFormInstalled: boolean,
) {
  const {
    getControllerState,
    context: { biLogger, wixSdkAdapter, businessInfo, experiments },
  } = actionFactoryParams;
  const [state, setState] = getControllerState();
  const {
    selectedTime,
    availableServices,
    selectedTimezone,
    selectedVariantsOptions,
  } = state;
  const isRescheduling = !!state.rescheduleBookingDetails;
  void biLogger.report(
    bookingsContactInfoSaveSuccess({
      selectedSlot: selectedTime,
      ...mapFlowTypeToBiProperties({ isWaitingList, isBookingFormInstalled }),
    }),
  );

  if (isRescheduling) {
    setState({
      dialog: {
        type: DialogType.RescheduleConfirm,
        state: DialogState.IDLE,
      },
    });
  } else if (isWaitingList) {
    setState({
      dialog: {
        type: DialogType.JoinWaitlist,
        state: DialogState.IDLE,
      },
    });
  } else {
    const slotAvailability = selectedSlots[0];
    const isDynamicPricingUoUEnabled = experiments.enabled(
      'specs.bookings.dynamicPricingUoU',
    );
    const service = availableServices[0];
    const serviceId = service!.id;
    const serviceSlug = service!.info.slug;
    let dynamicPriceInfo: DynamicPriceInfo | undefined;
    if (isDynamicPricingUoUEnabled) {
      dynamicPriceInfo = getDynamicPricingInfo(selectedVariantsOptions);
    }
    await wixSdkAdapter.navigateToBookingsFormPage({
      slotAvailability,
      serviceId,
      serviceSlug,
      referral: BOOKINGS_CALENDAR_REFERRAL_INFO,
      timezone: selectedTimezone,
      dynamicPriceInfo,
    });

    const services = availableServices;
    if (services.length) {
      const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
        services,
        businessName: businessInfo?.name || '',
      });
      wixSdkAdapter.trackAnalytics(trackingInfo);
    }
  }
}
