import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';
import {
  BookingPreference,
  BookingPreferenceOption,
  getBookingPreferences,
  SelectedBookingPreference,
} from './bookingPreferences';
import { isSlotWithOpenWaitingList } from '../slotAvailability/slotAvailability';
import { CalendarErrors, Preference } from '../../types/types';

export const getBookingPreferencesForSelectedTime = ({
  selectableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
  context,
  state,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  context: CalendarContext;
  state: CalendarState;
}): BookingPreference[] => {
  const bookingPreferences = getBookingPreferences({
    context,
    state,
  });
  return createBookingPreferencesForSelectedTime({
    bookingPreferences,
    selectableSlotsAtSelectedTime,
    selectedBookingPreferences,
    calendarErrors,
    context,
    state,
  });
};

const createBookingPreferencesForSelectedTime = ({
  bookingPreferences,
  selectableSlotsAtSelectedTime,
  selectedBookingPreferences,
  calendarErrors,
  context,
  state,
}: {
  bookingPreferences: BookingPreference[];
  selectableSlotsAtSelectedTime: SlotAvailability[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  context: CalendarContext;
  state: CalendarState;
}): BookingPreference[] => {
  return bookingPreferences.map(
    (currentBookingPreference: BookingPreference) => {
      const { key, error, placeholder, isMultipleChoices, disabled, id } =
        currentBookingPreference;
      const hasError = calendarErrors.includes(error.key);
      const options = createOptionsForBookingPreference({
        selectableSlotsAtSelectedTime,
        bookingPreferences,
        selectedBookingPreferences,
        currentBookingPreference,
        state,
      });
      const note = createNoteForBookingPreference({
        selectableSlotsAtSelectedTime,
        bookingPreferences,
        selectedBookingPreferences,
        currentBookingPreference,
        context,
      });
      const openSpotsRemained = createOpenSpotsRemainedForBookingPreference({
        selectableSlotsAtSelectedTime,
        bookingPreferences,
        selectedBookingPreferences,
        currentBookingPreference,
        state,
      });

      const selectableOptions = options.filter((option) => option.isSelectable);

      const preselectedOptionId =
        selectableOptions.length === 1 ? selectableOptions[0].id : undefined;

      return {
        id,
        isMultipleChoices,
        openSpotsRemained,
        preselectedOptionId,
        note,
        disabled,
        key,
        options,
        error: {
          key: error.key,
          message: hasError ? error.message : '',
        },
        placeholder,
      };
    },
  );
};

const createOpenSpotsRemainedForBookingPreference = ({
  selectableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  currentBookingPreference,
  state,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  currentBookingPreference: BookingPreference;
  state: CalendarState;
}): number => {
  if (currentBookingPreference.key === Preference.CUSTOM) {
    const { selectedVariantsOptions, availableServices } = state;
    const totalParticipants = selectedVariantsOptions.reduce(
      (currentNumberOfParticipants, { numberOfParticipants }) =>
        currentNumberOfParticipants + numberOfParticipants,
      0,
    );

    const maxParticipantsPerBook =
      availableServices[0].policy.maxParticipantsPerBook;

    const filteredBookableSlots: SlotAvailability[] =
      filterBookableSlotsAccordingToSelectedPreferences({
        selectableSlotsAtSelectedTime,
        bookingPreferences,
        selectedBookingPreferences,
        currentBookingPreference,
      });

    return (
      Math.min(maxParticipantsPerBook, filteredBookableSlots[0].openSpots!) -
      totalParticipants
    );
  }

  return 1;
};

const createNoteForBookingPreference = ({
  selectableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  currentBookingPreference,
  context,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  currentBookingPreference: BookingPreference;
  context: CalendarContext;
}): string => {
  if (currentBookingPreference.key === Preference.CUSTOM) {
    const { t } = context;
    const filteredBookableSlots: SlotAvailability[] =
      filterBookableSlotsAccordingToSelectedPreferences({
        selectableSlotsAtSelectedTime,
        bookingPreferences,
        selectedBookingPreferences,
        currentBookingPreference,
      });

    return t('app.booking-details.dropdowns.custom-preferences.note.text', {
      openSpotsRemained: filteredBookableSlots[0].openSpots,
    });
  }

  return '';
};

const createOptionsForBookingPreference = ({
  selectableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  currentBookingPreference,
  state,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  currentBookingPreference: BookingPreference;
  state: CalendarState;
}): BookingPreferenceOption[] => {
  const filteredBookableSlots: SlotAvailability[] =
    filterBookableSlotsAccordingToSelectedPreferences({
      selectableSlotsAtSelectedTime,
      bookingPreferences,
      selectedBookingPreferences,
      currentBookingPreference,
    });
  if (currentBookingPreference.getBookingPreferenceOptionFromSlot) {
    let bookingPreferenceOptions: BookingPreferenceOption[] = [];
    selectableSlotsAtSelectedTime.forEach((bookableSlots) => {
      const slotBookingPreferenceOption =
        currentBookingPreference.getBookingPreferenceOptionFromSlot!(
          bookableSlots,
        );

      const isSelectable = isBookingPreferenceOptionSelectable({
        filteredBookableSlots,
        currentBookingPreference,
        slotBookingPreferenceOption,
      });

      const isWithWaitingList = isBookingPreferenceOptionWithWaitingList({
        selectableSlotsAtSelectedTime,
        currentBookingPreference,
        slotBookingPreferenceOption,
      });
      bookingPreferenceOptions.push({
        ...slotBookingPreferenceOption,
        isWithWaitingList,
        isSelectable,
      });
    });

    bookingPreferenceOptions = removeDuplicatePreferenceOptions(
      bookingPreferenceOptions,
    );

    return bookingPreferenceOptions;
  } else if (
    currentBookingPreference.getBookingPreferenceOptionsFromSelectedVariantsOptions
  ) {
    return currentBookingPreference
      .getBookingPreferenceOptionsFromSelectedVariantsOptions(
        state.selectedVariantsOptions,
      )
      .map((option) => ({
        ...option,
        isSelectable: true,
      }));
  }
  return [];
};

const removeDuplicatePreferenceOptions = (
  bookingPreferenceOptions: BookingPreferenceOption[],
): BookingPreferenceOption[] => {
  const uniqueBookingPreferenceOptions: BookingPreferenceOption[] = [];

  bookingPreferenceOptions.forEach((bookingPreferenceOption) => {
    const uniqueBookingPreferenceOptionWithTheSameId =
      uniqueBookingPreferenceOptions.find(
        (uniqueBookingPreferenceOption: BookingPreferenceOption) =>
          bookingPreferenceOption.id === uniqueBookingPreferenceOption.id,
      );

    const isBookingPreferenceOptionNotExists =
      !uniqueBookingPreferenceOptionWithTheSameId;
    if (isBookingPreferenceOptionNotExists) {
      uniqueBookingPreferenceOptions.push(bookingPreferenceOption);
    }
  });

  return uniqueBookingPreferenceOptions;
};

const filterBookableSlotsAccordingToSelectedPreferences = ({
  selectableSlotsAtSelectedTime,
  bookingPreferences,
  selectedBookingPreferences,
  currentBookingPreference,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  bookingPreferences: BookingPreference[];
  selectedBookingPreferences?: SelectedBookingPreference[];
  currentBookingPreference: BookingPreference;
}): SlotAvailability[] => {
  let filteredBookableSlotsAtSelectedTime = selectableSlotsAtSelectedTime;
  bookingPreferences.forEach((preference: BookingPreference) => {
    const selectedBookingPreference = selectedBookingPreferences?.find(
      (selectedPreference) => preference.key === selectedPreference.key,
    );

    if (
      selectedBookingPreference &&
      preference.key !== currentBookingPreference.key &&
      preference.key !== Preference.CUSTOM
    ) {
      filteredBookableSlotsAtSelectedTime =
        filteredBookableSlotsAtSelectedTime.filter(
          (bookableSlot) =>
            preference.getBookingPreferenceOptionFromSlot!(bookableSlot).id ===
            selectedBookingPreference.value,
        );
    }
  });
  return filteredBookableSlotsAtSelectedTime;
};

const isBookingPreferenceOptionSelectable = ({
  filteredBookableSlots,
  currentBookingPreference,
  slotBookingPreferenceOption,
}: {
  filteredBookableSlots: SlotAvailability[];
  currentBookingPreference: BookingPreference;
  slotBookingPreferenceOption: BookingPreferenceOption;
}) => {
  return filteredBookableSlots.some((filteredSlot: SlotAvailability) => {
    return (
      currentBookingPreference.getBookingPreferenceOptionFromSlot!(filteredSlot)
        .id === slotBookingPreferenceOption.id
    );
  });
};

const isBookingPreferenceOptionWithWaitingList = ({
  selectableSlotsAtSelectedTime,
  currentBookingPreference,
  slotBookingPreferenceOption,
}: {
  selectableSlotsAtSelectedTime: SlotAvailability[];
  currentBookingPreference: BookingPreference;
  slotBookingPreferenceOption: BookingPreferenceOption;
}) => {
  return selectableSlotsAtSelectedTime
    .filter((bookableSlot) => {
      return (
        currentBookingPreference.getBookingPreferenceOptionFromSlot!(
          bookableSlot,
        ).id === slotBookingPreferenceOption.id
      );
    })
    .every(isSlotWithOpenWaitingList);
};
